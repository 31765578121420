
import { Options, Vue } from 'vue-class-component';
import { GameClient } from '@/services/Services';
import ErrorModal from '@/modals/errorModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class RegisterSecondUser extends Vue {

    model: OM.CompleteSecondPartecipantVM = new OM.CompleteSecondPartecipantVM();
    emailAddress: string = "";
    step: number = 1;

    created() {
        this.model.userExtractionResumeIdentifier = this.$route.params.userExtractionResumeIdentifier.toString();
        this.emailAddress = this.$route.params.emailAddress.toString();
    }

    save() {
        GameClient.completeSecondPartecipant(this.model)
        .then(x => {
            this.step = 2;
        })
        .catch(y => {
            this.$opModal.show(ErrorModal, {
                title: this.$localizationService.getLocalizedValue('app_errore', 'Errore'),
                description: this.$localizationService.getLocalizedValue('app_Qualcosa è andato storto', 'Qualcosa è andato storto'),
            })
        })
    }

    get disabled() {
        return !this.model.name || !this.model.surname;
    }

}
